(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("@scuf/common"));
	else if(typeof define === 'function' && define.amd)
		define("@connectedpower/connectedpower.Energy_Estimation_Webapp", ["react", "@scuf/common"], factory);
	else if(typeof exports === 'object')
		exports["@connectedpower/connectedpower.Energy_Estimation_Webapp"] = factory(require("react"), require("@scuf/common"));
	else
		root["@connectedpower/connectedpower.Energy_Estimation_Webapp"] = factory(root["react"], root["@scuf/common"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__9155__, __WEBPACK_EXTERNAL_MODULE__5911__) => {
return 