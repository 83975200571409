import React, { lazy } from 'react';
import './App.css';
import logo from './assets/logo.svg';
import { EnergyCalculator } from '@connectedpower/connectedpower.Energy_Estimation_Webapp';

function App() {
  return (
    <div className="App">
      {/* <section className="headings">
        <div className="logo-container">
          <img src={logo} alt="Honeywell" className="honeywell-title" />
          <span className="vertical-divider" />
          <div className="smartview-text">BUILDING AUTOMATION</div>
        </div>
      </section> */}
      <div>
        <EnergyCalculator></EnergyCalculator>
      </div>

      
    </div>
  );
}

export default App;
